import * as React from "react";
import { Link } from "gatsby";

// styles

// markup
const NotFoundPage = () => {
  return (
    <main style={{ padding: "1rem", marginLeft: "4rem" }}>
      <title>404 | CiteDrive.com</title>
      <h1>404 | CiteDrive.com</h1>
      <h2>The page you’re looking for can’t be found. 🤕</h2>
      <h3>Go back to <a
        href="https://citedrive.com/"
        style={{
          color: "#1e4288",
          borderBottom: "1px solid #1e4288",
          fontWeight: "500",
        }}
      >
        CiteDrive.com
      </a></h3>
    </main>
  );
};

export default NotFoundPage;
